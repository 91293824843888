<template>
  <div class="shop__popup--wrapper">
    <div class="open-popup-editshop shop__popup">
      <button class="km__button--close" type="button" name="close" @click="$emit('close_popup')"></button>
      <span class="missing-personalData" v-if="anz_missingPersonalData !== 0">{{ anz_missingPersonalData }}</span>
      <span class="missing-steuerData" v-if="anz_missingSteuerData !== 0">{{ anz_missingSteuerData }}</span>
      <span class="missing-zahlungsData" v-if="anz_missingZahlungsData !== 0">{{ anz_missingZahlungsData }}</span>
      <Tabs :initialTab="$t('shop_PersonalData')" @click="resetStatus" ref="buttonRef">
        <Tab :name="$t('shop_PersonalData')"><EditAccountPersonalData  @open_adressauswahl="showAdressen" @close_popup="$emit('close_popup')" @save="saveShopImpressum" /></Tab>
        <Tab :name="$t('shop_steuerStatus')"><EditAccountSteuerstatus @close_popup="$emit('close_popup')" @save="saveShopImpressum" /></Tab>
        <Tab :name="$t('shop_zahlungsverbindung')"><EditAccountZahlungsverbindung @close_popup="$emit('close_popup')" @save="saveShopImpressum" /></Tab>
      </Tabs>
    </div>
  </div>
</template>

<script lang="ts">
import Tabs from '@global/components/Tabs.vue';
import Tab from '@global/components/Tab.vue';
import EditAccountPersonalData from './EditAccountPersonalData.vue';
import EditAccountSteuerstatus from './EditAccountSteuerstatus.vue';
import EditAccountZahlungsverbindung from './EditAccountZahlungsverbindung.vue';
//@ts-ignore
import {NavigationOpenRequestTypeFromShoppingcartMenu} from "owayo_menu";

import {checkEmail, checkIBAN, checkAccountNumber, checkBIC, checkRoutingNumber} from "@k/global/globalUtils";
import {useShopStore} from "@ur/components/store/UserShopStore";
import type {ShopAdresse} from "@global/js/UserShop";
import {useShopInfos} from "~/store/shopInfosStore";

export enum SteuerStatus {
  stst_ohneRegistrierung = "stst_ohneRegistrierung",
  stst_kleinunternehmer = "stst_kleinunternehmer",
  stst_de19 = "stst_de19",
  stst_de7 = "stst_de7",
  stst_eu = "stst_eu",
  stst_nichtEU = "stst_nichtEU"
}

function isValidSteuerStatus(steuerstatus:SteuerStatus, isFirmaOderJuristischePerson:boolean, isDE:boolean, isEU:boolean):boolean {
  if(!isFirmaOderJuristischePerson && steuerstatus === SteuerStatus.stst_ohneRegistrierung) {
    return true;
  }
  if(isFirmaOderJuristischePerson && steuerstatus === SteuerStatus.stst_ohneRegistrierung) {
    return false;
  }
  if(!isEU && steuerstatus !== SteuerStatus.stst_nichtEU) {
    return false;
  }
  // noinspection RedundantIfStatementJS
  if(!isDE && ([SteuerStatus.stst_de7, SteuerStatus.stst_de19, SteuerStatus.stst_kleinunternehmer] as SteuerStatus[]).includes(steuerstatus)){
    return false;
  }
  return true;
}

export default {
  name: "PopupEditImpressum",
  components: {
    Tabs,
    Tab,
    EditAccountPersonalData,
    EditAccountSteuerstatus,
    EditAccountZahlungsverbindung
  },
  setup(props, context){
    const buttonRef = ref(null);
    const userShop = useShopStore();
    const i18n = useI18n();
    const shopInfos = useShopInfos();
    userShop.loadIfNotAlreadyLoaded();

    function changeToTab(idInContentTable) {
      (buttonRef.value as {
        clickAButtonFromParent: (s: string) => void
      }).clickAButtonFromParent(i18n.t(idInContentTable));
    }

    function saveShopImpressum() {
      const emailValidStr = checkEmail(userShop.getEmailToVerify);
      if(userShop.getEmailToVerify!==""){
        userShop.SET_USERSHOP_EMAILCHECK(emailValidStr);
      }

      const ibanValid = (userShop.getZahlungsverbindung==='zv_paypal' || userShop.getZahlungsverbindung===null|| (shopInfos.getShopLand==="us" ? checkAccountNumber(userShop.getZahlungsverbindungIBAN) : checkIBAN(userShop.getZahlungsverbindungIBAN)));
      const bicValid = (userShop.getZahlungsverbindung==='zv_paypal' || userShop.getZahlungsverbindung===null|| (shopInfos.getShopLand==="us" ? checkRoutingNumber(userShop.getZahlungsverbindungBIC) : checkBIC(userShop.getZahlungsverbindungBIC)));
      const emailValid = (userShop.getEmailToVerify==="" || emailValidStr==="valid");
      const steuerstatusValid = isValidSteuerStatus(
          userShop.getSteuerstatus as SteuerStatus,
          userShop.getIsFirmaOrPerson as boolean,
          (userShop.getUserShopAdresse as ShopAdresse).iso3166==='DE',
          (userShop.getUserShopAdresse as ShopAdresse).isEU
      );
      let alreadyChangedtab = false;

      if(!emailValid) {
        changeToTab('shop_PersonalData');
        alreadyChangedtab = true;
      }

      if(!steuerstatusValid && !alreadyChangedtab) {
        changeToTab('shop_steuerStatus');
        alreadyChangedtab = true;

      }

      if(!ibanValid){
        userShop.SET_USERSHOP_IBANCHECKFAILED();
        if(!alreadyChangedtab) {
          changeToTab('shop_zahlungsverbindung');
        }
      }

      if(!bicValid){
        userShop.SET_USERSHOP_BICCHECKFAILED();
        if(!alreadyChangedtab) {
          changeToTab('shop_zahlungsverbindung');
        }
      }

      if(emailValid && ibanValid && steuerstatusValid && bicValid){
        userShop.SET_USERSHOP_IBANCHECKSUCCESS();
        userShop.SET_USERSHOP_BICCHECKSUCCESS();
        userShop.setUserShopStatus("");
        userShop.changeZahlungsdaten();
        userShop.changeShopData();
      }
    }

    function resetStatus() {
      userShop.SET_USERSHOP_STATUS("");
    }

    return{
      saveShopImpressum,
      resetStatus,
      showAdressen: () => {
        userShop.SET_USERSHOP_STATUS("");
        context.emit('open_component_request', NavigationOpenRequestTypeFromShoppingcartMenu.openChooseAdresse)
      },
      buttonRef,
      anz_missingPersonalData:computed(()=>userShop.getAnzMissingPersonalData),
      anz_missingSteuerData:computed(()=>userShop.getAnzMissingSteuerData),
      anz_missingZahlungsData:computed(()=>userShop.getAnzMissingZahlungsData)
    }
  }
}
</script>

<style lang="scss" scoped>
.open-popup-editshop {
  max-width: 600px;
}

:deep(.tabs--tabButtons) {
  justify-content: space-between;
}

:deep(.tab--link) {
  padding: 12px 20px;
  background-color: transparent;
}

:deep(.tab--link.active) {
  background-color: $color-stage-bg;
}

.missing-personalData {
  position: absolute;
  left: 30px;
  //bottom: 10px;
  background-color: #e03d63;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  padding-top: 1px;
  padding-left: 6px;
  color: white;
}

.missing-steuerData {
  position: absolute;
  left: 240px;
  //bottom: 10px;
  background-color: #e03d63;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  padding-top: 1px;
  padding-left: 6px;
  color: white;
}

.missing-zahlungsData {
  position: absolute;
  left: 430px;
  //bottom: 10px;
  background-color: #e03d63;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  font-family: Arial, sans-serif;
  padding-top: 1px;
  padding-left: 6px;
  color: white;
}
</style>
