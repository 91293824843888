<template>
  <div>
    <div class="shop__personal--data">
      <!--<h3>{{$t('shop_PersonalData')}}</h3>-->
      <div class="personal--data--firma">
        <input type="checkbox" :checked="isFirmaOrPerson" v-model="isFirmaOrPerson"/>
        <label class="km__label--text">{{ $t("shop_juristischePerson") }}</label>
      </div>
      <div>
        <div>
          <label class="km__label--text" for="shopEmailID">{{ $t('konf_Email') }}</label>
          <input class="km__input--standard" type="text" name="Shop" v-model="shopEmail" id="shopEmailID">
        </div>
        <div v-if="emailError">
          <label class="km__label--saveError">{{ getEmailErrorStr }}</label>
        </div>
        <div v-if="emailToVerifyExists">
          <label class="km__label--text">{{ getVerificationText() }}</label>
        </div>
      </div>
      <div>
        <label class="km__label--text" for="shopTelefonID">{{ $t('konf_Tel') }}</label>
        <input class="km__input--standard" type="text" name="Shop" v-model="shopTelefon" id="shopTelefonID">
      </div>
      <div class="shop__ihre--daten">
        <p>
          <strong>{{ shopAdresse?.firma }}</strong><br/>
          {{ shopAdresse?.ansprechpartner }}<br/>
          {{ shopAdresse?.strasse }}<br/>
          {{ shopAdresse?.iso3166 }} {{ shopAdresse?.plz }} {{ shopAdresse?.ort }}<br/>
        </p>
      </div>
      <button :class="{ 'km__button--link-missing': fehlenAdressDaten, 'km__button--link': !fehlenAdressDaten }"  @click="$emit('open_adressauswahl')">{{ $t("konf_selectAdresse") }}</button>
      <!--        <RouterLink to="/adresses/choose"><button class="km__button&#45;&#45;standard">{{ $t("konf_selectAdresse") }}</button></RouterLink>-->
      <div v-if="saveSuccessful">
        <label class="km__label--saveSuccess">{{ $t('shop_AenderungenGespeichert') }}</label>
      </div>
    </div>
    <div class="km__popup--buttons">
      <button class="km__button--standard" @click="$emit('close_popup')">{{ $t("konf_Close") }}</button>
      <button class="km__button--standard blue" @click="$emit('save')">{{ $t("konf_Save") }}</button>
    </div>
  </div>
</template>

<script lang="ts">
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {useShopStore} from "@ur/components/store/UserShopStore";

export default {
  name: "EditAccountPersonalData",
  emits: ["open_adressauswahl", "close_popup", "save"],
  setup() {
    const userShop = useShopStore()
    userShop.loadIfNotAlreadyLoaded();
    const i18n = useI18n();
    // const shopAdresse= ref({firma:"", ansprechpartner:"", strasse:"", iso3166:"", plz:"", ort:""});
    // watch(()=>store.getters.getUserShopAdresse, adresse=>shopAdresse.value = adresse)

    const shopEmail = computed({
      get() {
        return userShop.getUserShopEmail
      },
      set(newValue) {
        userShop.SET_USERSHOP_STATUS("");
        userShop.SET_USERSHOP_EMAIL(newValue as string);
        userShop.SET_USERSHOP_VERIFYEMAIL(newValue as string);
      }
    });
    const shopTelefon = computed({
      get() {
        return userShop.getUserShopTelefon
      },
      set(newValue) {
        userShop.SET_USERSHOP_STATUS("");
        userShop.SET_USERSHOP_TELEFON(newValue as string);
      }
    });
    const isFirmaOrPerson = computed({
      get() {
        return userShop.getIsFirmaOrPerson
      },
      set(newValue) {
        userShop.SET_USERSHOP_STATUS("");
        userShop.SET_USERSHOP_ISFIRMAORPERSON(newValue as boolean);
      }
    });

    function getVerificationText() {
      return i18n.t("shop_VerifyEmail_disclaimer").replace("[Email]", userShop.getEmailToVerify as string);
    }

    const getEmailErrorStr = computed(() => {
      return userShop.getEmailToVerifyCheck !== "" ? i18n.t(userShop.getEmailToVerifyCheck || "").replace("_at_", "@") : "";
    });

    return {
      shopEmail,
      shopTelefon,
      isFirmaOrPerson,
      getEmailErrorStr,
      getVerificationText,
      emailError: computed(() => userShop.getEmailToVerifyCheck !== "" && userShop.getEmailToVerifyCheck !== "valid"),
      saveSuccessful: computed(() => userShop.getUserShopStatus === "success"),
      emailToVerifyExists: computed(() => (userShop.getEmailToVerify !== "" && userShop.getUserShopStatus === "success") ||
          (userShop.getEmailToVerify !== "" && userShop.getEmailToVerify !== userShop.getUserShopEmail)
      ),
      shopAdresse: computed(() => userShop.getUserShopAdresse),
      fehlenAdressDaten: computed(() => userShop.getFehlenAdressDaten)

    }
  }
}
</script>

<style lang="scss" scoped>
.shop__personal--data {
  background-color: $color-stage-bg;
  padding: 20px;
  height: 500px;

  h3 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .km__input--standard {
    background-color: #F6F6F6;
  }

  .km__label--text {
    font-size: 13px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .km__button--link {
    display: inline-block;
    width: auto;
    border-bottom: 1px solid $color-blue;
  }

  .km__button--link-missing {
    display: inline-block;
    width: auto;
    border-bottom: 1px solid $color-red;
    color: $color-red;
  }

  .km__label--saveSuccess {
    font-size: 13px;
    margin-bottom: 4px;
    font-weight: 500;
    color: green;
    display: block;
    padding-top: 30px;
  }

  .km__label--saveError {
    font-size: 13px;
    margin-bottom: 5px;
    font-weight: 500;
    color: red;
    display: block;
    padding-top: 3px;
  }
}

.personal--data--firma {
  display: flex;
  margin-bottom: 25px;

  input {
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0;
  }

  .km__label--text {
    margin-left: 10px;
  }
}

.shop__ihre--daten {

  p {
    font-size: 13px;
    line-height: 20px;
  }
}
</style>
